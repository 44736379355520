import { ExtractFnReturnType, QueryConfig, queryClient } from "libs/react-query";
import { useQuery } from "@tanstack/react-query";
import { TMDBType } from "./types";
import axiosTmdb from "./axiosTmdb";

interface IParamsEpisodeDetails {
  tmdb_id: number;
  episodeId: number;
  seasonId: number;
  language?: string;
}

export const getEpisodeDetailsTmdb = async (
  params: IParamsEpisodeDetails
): Promise<TMDBType.IEpisode | null> => {
  try {
    let detailsEpisodeTMDB: TMDBType.IEpisode = await axiosTmdb(
      `/tv/${params.tmdb_id}/season/${params.seasonId || 1}/episode/${params.episodeId || 1}`,
      {
        params: { append_to_response: "external_ids", language: "pt-BR" },
      }
    );
    return detailsEpisodeTMDB;
  } catch (error) {
    console.log("error: ", error);
    return null;
  }
};

type QueryFnType = typeof getEpisodeDetailsTmdb;

type UseEpisodeDetailsTmdbOptions = {
  params: IParamsEpisodeDetails;
  config?: QueryConfig<QueryFnType>;
};

const primaryKey = "EpisodeDetailsSeries";

export const useEpisodeDetailsTmdb = ({ config = {}, params }: UseEpisodeDetailsTmdbOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [primaryKey, params],
    queryFn: () => getEpisodeDetailsTmdb(params),
  });
};

export const getQueryDataEpisodeDetailsTmdb = (params: IParamsEpisodeDetails) => {
  return queryClient.getQueryData([primaryKey, params]) as TMDBType.IEpisode;
};
