import { useSubtitleStateContext } from "player/contexts/SubtitleStateContext";
import { useVideoFewUpdatesContext } from "player/contexts/VideoFewUpdatesContext";
import { useEffect, useState } from "react";
import toWebVTT from "srt-webvtt";

const SubtitleVideoCaptionIOS = () => {
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { mediaState } = useSubtitleStateContext();
  const [subtitleUrl, setSubtitleUrl] = useState("");
  useEffect(() => {
    const getSubtitle = async () => {
      try {
        if (!mediaState.subtitleSelected.url) return;
        if (mediaState.subtitleSelected.url.includes("srt")) {
          const response = await fetch(mediaState.subtitleSelected.url);
          const text = await response.text();
          const blobVTT = new Blob([text], { type: `text/srt;charset=ISO-8859-1` });
          const path = await toWebVTT(blobVTT);
          setSubtitleUrl(path);
          return;
        }
        setSubtitleUrl(mediaState.subtitleSelected.url);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    getSubtitle();
  }, [mediaState.subtitleSelected.url]);
  if (!mediaState.subtitleSelected.url && videoFewUpdatesContext.loadedData && !subtitleUrl) {
    return null;
  }
  return (
    <track
      kind="subtitles"
      srcLang={mediaState.subtitleSelected.lang}
      label={mediaState.subtitleSelected.language}
      src={subtitleUrl}
      default
    />
  );
};

export default SubtitleVideoCaptionIOS;
