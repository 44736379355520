import classNames from "../../../utils/classNames";
import React from "react";
import IconCheck from "../../components/Icons/IconCheck";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";

interface SettingMenuCheckedProps extends React.HTMLAttributes<HTMLDivElement> {
  settingSection: SettingSections;
  isActive?: React.ReactNode;
  value?: string | number;
  handleOnClick?: () => void;
}

const SettingMenuChecked = ({
  settingSection,
  handleOnClick,
  isActive,
  value,
  className,
  ...props
}: SettingMenuCheckedProps) => {
  const { setActiveSection } = useSettingsContext();
  return (
    <div
      onClick={() => {
        setActiveSection(settingSection);
        handleOnClick && handleOnClick();
      }}
      className={classNames("embed-settings-item-2", className)}
      {...props}
    >
      <div className="embed-settings-item-left">
        {isActive ? (
          <div className="flex items-center justify-center w-6 h-6">
            <IconCheck className="w-5 h-5" />
          </div>
        ) : (
          <div style={{ width: 24, height: 24, flexShrink: 0 }}></div>
        )}
      </div>
      {value}
    </div>
  );
};

export default SettingMenuChecked;
