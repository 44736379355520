import { RefObject, memo } from "react";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import useVideoProps, { IVideoProps } from "../../hooks/useVideoProps";
import { ISource } from "../../types";
import VideoHls from "./VideoHls";

interface VideoBaseMemoProps {
  playerRef: RefObject<HTMLVideoElement> | undefined;
  videoProps: IVideoProps;
  currentSource: ISource;
}

const VideoBaseMemo = memo(({ playerRef, currentSource, videoProps }: VideoBaseMemoProps) => {
  if (videoProps?.src?.includes("m3u8")) {
    return <VideoHls playerRef={playerRef as any} {...videoProps} currentSource={currentSource} />;
  }
  return <video ref={playerRef} {...videoProps}></video>;
});

VideoBaseMemo.displayName = "VideoBaseMemo";

const VideoBase = () => {
  const { playerRef } = useGlobalStateContext();
  const { videoProps, currentSource } = useVideoProps();
  return (
    <VideoBaseMemo playerRef={playerRef} videoProps={videoProps} currentSource={currentSource} />
  );
};

export default VideoBase;
