import { ISeason } from "backend/tmdb/types";
import { useSearchParams } from "react-router-dom";
import classNames from "utils/classNames";

interface SeriesSourceSeasonsPickerProps {
  seasons: ISeason[];
  handleChangeSelectSeason: (season: ISeason) => void;
}

const SeriesSourceSeasonsPicker = ({
  seasons,
  handleChangeSelectSeason,
}: SeriesSourceSeasonsPickerProps) => {
  const [searchParams] = useSearchParams();
  const seasonId = Number(searchParams.get("season") || 1);
  return (
    <div className="px-3 py-4 md:px-6">
      <div className="pb-5">
        <p className="text-xl font-medium">Temporadas</p>
        <span className="font-light">Relógio da primeira temporada escolhido</span>
      </div>
      <ul>
        {seasons.map((season) => (
          <li
            key={season.id}
            className={classNames(
              "cursor-pointer rounded -mx-2 p-3 text-sm md:text-base transition-[background-color,color] duration-150 md:hover:bg-zinc-800 md:hover:text-blue-300 border-zinc-700 text-white line-clamp-1",
              Number(seasonId) === season.season_number && "border"
            )}
            onClick={() => handleChangeSelectSeason(season)}
          >
            {season.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeriesSourceSeasonsPicker;
