import { ButtonHTMLAttributes, Dispatch, memo } from "react";
import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";
import {
  IVideoLotsOfUpdates,
  useVideoLotsOfUpdatesContext,
} from "../../contexts/VideoLotsOfUpdatesContext";
import IconSettings from "../../components/Icons/IconSettings";

interface ButtonOpenSettingsMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  settingsActive: boolean;
  setVideoLotsOfUpdatesContext: Dispatch<React.SetStateAction<IVideoLotsOfUpdates>>;
}

const ButtonOpenSettingsMemo = memo((props: ButtonOpenSettingsMemoProps) => {
  const { settingsActive, setVideoLotsOfUpdatesContext, ...others } = props;
  const { i18n } = useI18nContext();
  const handleClickCapture = () =>
    setVideoLotsOfUpdatesContext((prevState) => ({
      ...prevState,
      settingsActive: !prevState.settingsActive,
    }));
  const showTooltips = !settingsActive
    ? {
        "data-embed-tooltips": i18n?.tooltipsSettings || defaultI18n.tooltipsSettings,
      }
    : {};
  return (
    <button
      className="embed-center-container embed-scale"
      onClickCapture={handleClickCapture}
      {...showTooltips}
      {...others}
    >
      <IconSettings className="embed-icon-lg" />
    </button>
  );
});

ButtonOpenSettingsMemo.displayName = "ButtonOpenSettingsMemo";

const ButtonOpenSettings = () => {
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  return (
    <ButtonOpenSettingsMemo
      className="embed-center-container embed-scale"
      setVideoLotsOfUpdatesContext={setVideoLotsOfUpdatesContext}
      settingsActive={videoLotsOfUpdatesContext.settingsActive}
    />
  );
};

export default ButtonOpenSettings;
