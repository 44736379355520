import { allServers, useMediaSources } from "backend/movie-providers/main-providers";
import { IParamsMediaSource } from "backend/movie-providers/types";
import { useMovieDetailsTmdb } from "backend/tmdb/useMovieDetailsTmdb";
import LoadingMovieScreen from "components/LoadingMovieScreen";
import PopoverServersPicker from "components/PopoverServersPicker";
import useVideoServersStore from "store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { MediaType } from "types";
import { WRAPPER_PLAYER_ID } from "player/constants";
import EmbedPlayer from "player";
import { sortDefaultLanguage } from "player/utils/helper";
import EmbedTmdbPlayer from "components/EmbedTmdbPlayer";
import useSaveWatched from "player/hooks/useSavedWatched";
import { TMDBType } from "backend/tmdb/types";
import { getTitleFromMovieDetails } from "utils/helper";
import { imageTMDB } from "backend/tmdb/image";

const WatchMoviePage = () => {
  const [searchParams] = useSearchParams();
  const tmdb_id = searchParams.get("tmdb_id");
  const { data: details, isLoading: isLoadingTmdb } = useMovieDetailsTmdb({
    params: {
      tmdb_id: tmdb_id || "",
    },
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * (60 * 1000),
      enabled: Boolean(tmdb_id),
    },
  });
  const paramsMediaSources: IParamsMediaSource = {
    title: getTitleFromMovieDetails(details as TMDBType.IMovieDetails),
    extraData: {
      tmdb_id: details?.id,
      imdb_id: details?.imdb_id,
      mediaType: MediaType.MOVIE,
      titlePortuguese: details?.title_portuguese,
      year: new Date(details?.release_date as any).getFullYear(),
    },
  };
  const { startTime, handleSaveProgressWatched } = useSaveWatched({
    details: details as TMDBType.IMovieDetails,
    title: getTitleFromMovieDetails(details as TMDBType.IMovieDetails),
    mediaType: MediaType.MOVIE,
  });
  const { activeServer, setActiveServer, setServers } = useVideoServersStore((state) => state);
  const { isLoading: isLoadingSources, data: dataMediaSources } = useMediaSources({
    params: paramsMediaSources,
    config: {
      enabled: Boolean(details?.id),
      onSuccess(data) {
        setServers(data.servers);
        setActiveServer(data.activeServer);
      },
    },
  });
  useEffect(() => {
    setServers(allServers);
    setActiveServer(allServers[0].name);
  }, [tmdb_id, setActiveServer, setServers]);
  if (isLoadingTmdb) return <LoadingMovieScreen />;
  if (activeServer === "Embed") {
    return (
      <EmbedTmdbPlayer
        urlEmbed={`https://embed.embedplayer.site/${details?.imdb_id}`}
        handleGoBack={() => {
          setServers(allServers);
          setActiveServer(allServers[0].name);
        }}
      />
    );
  }
  return (
    <div className="fixed w-full h-full" id={WRAPPER_PLAYER_ID}>
      <EmbedPlayer
        poster={imageTMDB.imageOriginal(details?.backdrop_path as string)}
        sources={dataMediaSources?.mediaSources.sources || []}
        subtitles={sortDefaultLanguage(dataMediaSources?.mediaSources.subtitles || [])}
        meta={{
          imdb_id: paramsMediaSources.extraData.imdb_id,
          title: paramsMediaSources.title,
          year: paramsMediaSources.extraData.year,
        }}
        startTime={startTime}
        onTimeUpdate={handleSaveProgressWatched}
        heading={getTitleFromMovieDetails(details as TMDBType.IMovieDetails)}
        rightControlsComponent={
          <PopoverServersPicker
            isLoading={isLoadingSources}
            paramsMediaSources={paramsMediaSources}
          />
        }
      />
    </div>
  );
};

export default WatchMoviePage;
