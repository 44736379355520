import { useQuery } from "@tanstack/react-query";
import { ExtractFnReturnType, QueryConfig, queryClient } from "libs/react-query";
import axiosTmdb from "./axiosTmdb";
import { TMDBMediaType, TMDBType } from "./types";

export const getSeriesDetailsTmdb = async (
  params: IUseSeriesDetailsTmdbOptions
): Promise<TMDBType.ISeriesDetails | null> => {
  try {
    const promiseResults = await Promise.all([
      axiosTmdb(
        `/${TMDBMediaType.Tv}/${params.tmdb_id}?append_to_response=external_ids,translations&language=en`
      ),
      axiosTmdb(
        `/${TMDBMediaType.Tv}/${params.tmdb_id}?append_to_response=external_ids,translations&language=pt-BR`
      ),
    ]);
    const [detailsEnglish, detailsPortuguese] =
      promiseResults as unknown as TMDBType.ISeriesDetails[];
    detailsPortuguese.title_portuguese =
      detailsPortuguese.name ||
      detailsPortuguese.translations.translations.find(
        (t) => t && t?.iso_639_1 === "pt" && t?.data?.name
      )?.data?.name ||
      detailsPortuguese.original_name;
    detailsPortuguese.title_english =
      detailsEnglish.name ||
      detailsEnglish.translations.translations.find((t) => t?.iso_639_1 === "en" && t?.data?.name)
        ?.data.name ||
      detailsEnglish.original_name;
    const removeSpecials = ["Specials", "Especiais", "Especiales"];
    detailsPortuguese.seasons = detailsPortuguese.seasons.filter(
      (season) => !removeSpecials.includes(season.name)
    );
    console.log("detailsPortuguese: ", detailsPortuguese);
    return detailsPortuguese;
  } catch (error) {
    console.log("error: ", error);
    return null;
  }
};

type QueryFnType = typeof getSeriesDetailsTmdb;

interface IUseSeriesDetailsTmdbOptions {
  tmdb_id: string;
}

type UseSeriesDetailsTmdbOptions = {
  params: IUseSeriesDetailsTmdbOptions;
  config?: QueryConfig<QueryFnType>;
};

const primaryKey = "SeriesDetailsTmdb";

export const useSeriesDetailsTmdb = ({ config = {}, params }: UseSeriesDetailsTmdbOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [primaryKey, params],
    queryFn: () => getSeriesDetailsTmdb(params),
  });
};

export const getQueryDataSeriesDetailsTmdb = (params: IUseSeriesDetailsTmdbOptions) => {
  return queryClient.getQueryData([primaryKey, params]) as TMDBType.ISeriesDetails;
};
