import { Dispatch, SetStateAction, memo } from "react";
import {
  IVideoFewUpdatesContext,
  useVideoFewUpdatesContext,
} from "../../contexts/VideoFewUpdatesContext";

interface VideoVolumeSliderMemoProps {
  isMuted: boolean;
  volume: number;
  setVideoFewUpdatesContext: Dispatch<SetStateAction<IVideoFewUpdatesContext>>;
}

const VideoVolumeSliderMemo = memo(
  ({ isMuted, volume, setVideoFewUpdatesContext }: VideoVolumeSliderMemoProps) => {
    return (
      <div className="embed-volume-wrapper">
        <input
          className="embed-volume-slider"
          type="range"
          min={0}
          max={100}
          value={isMuted ? 0 : volume}
          onChange={(e) => {
            setVideoFewUpdatesContext((prevState) => ({
              ...prevState,
              isMuted: +e.target.value === 0,
              volume: +e.target.value,
            }));
          }}
        />
        <div className="embed-volume-left-bar" style={{ width: isMuted ? 0 : volume * 0.52 }}></div>
      </div>
    );
  }
);

VideoVolumeSliderMemo.displayName = "VideoVolumeSliderMemo";

const VideoVolumeSlider = () => {
  const { videoFewUpdatesContext, setVideoFewUpdatesContext } = useVideoFewUpdatesContext();
  return (
    <VideoVolumeSliderMemo
      isMuted={videoFewUpdatesContext.isMuted}
      volume={videoFewUpdatesContext.volume}
      setVideoFewUpdatesContext={setVideoFewUpdatesContext}
    />
  );
};

export default VideoVolumeSlider;
