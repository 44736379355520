import axios from "axios";
import { IParamsMediaSource } from "../types";
import { PATH_API } from "../../../constants";
import { load } from "cheerio";
import { baseUrlLoveflix } from "./constants";
import { compareTwoStrings } from "utils/helper";
import { MediaType } from "types";

interface ISearchResult {
  title: string;
  url: string;
}

export const search = async ({ extraData }: IParamsMediaSource): Promise<string> => {
  let title = extraData.titlePortuguese;
  const { data: html } = await axios(
    PATH_API.BYPASS_CORS_WORKERS(`${baseUrlLoveflix}/search/${title}`)
  );
  const $ = load(html);
  let searchResults: ISearchResult[] = [];
  $(".app-section .list-movie").each((_, movieEle) => {
    const regexRemoveTag = /Assistir\s*|\s*Online Grátis\s*|\s*Online/g;
    searchResults.push({
      title: $(movieEle).find(".list-title").text().trim().replace(regexRemoveTag, "")!,
      url: $(movieEle).find("a.list-media").attr("href")!,
    });
  });
  if (searchResults.length === 0) return "";
  // Sort in descending order
  searchResults.sort((a: ISearchResult, b: ISearchResult) => {
    const firstRating = compareTwoStrings(title, a.title);
    const secondRating = compareTwoStrings(title, b.title);
    return secondRating - firstRating;
  });
  searchResults = searchResults.filter((result) => {
    if (extraData.mediaType === MediaType.MOVIE)
      return result.url.startsWith(`${baseUrlLoveflix}/movie/`);
    else if (extraData.mediaType === MediaType.TV)
      return result.url.startsWith(`${baseUrlLoveflix}/serie/`);
    else return result;
  });
  console.log("searchResults: ", searchResults);
  return searchResults?.[0]?.url || "";
};
