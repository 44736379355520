const PageNotFound = () => {
  return (
    <div>
      <h2>404: Page Not Found</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adip.</p>
    </div>
  );
};

export default PageNotFound;
