import React from "react";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import ChevronRight from "../../components/Icons/ChevronRight";
import classNames from "../../../utils/classNames";

interface SettingMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  settingSection: SettingSections;
  icon?: React.ReactNode;
  title: string;
  value?: string | number;
  handleOnClick?: () => void;
}

const SettingMenuItem = ({
  settingSection,
  handleOnClick,
  icon,
  title,
  value,
  className,
  ...props
}: SettingMenuItemProps) => {
  const { setActiveSection } = useSettingsContext();
  return (
    <div
      onClick={() => {
        setActiveSection(settingSection);
        handleOnClick && handleOnClick();
      }}
      className={classNames("embed-settings-item", className)}
      {...props}
    >
      <div className="embed-settings-item-left">
        {icon && <div className="flex-shrink-0">{icon}</div>}
        <p className="flex-1">{title}</p>
      </div>
      {value && (
        <div className="embed-settings-item-right">
          <span>{value}</span>
          <ChevronRight className="embed-icon-xs" />
        </div>
      )}
    </div>
  );
};

export default SettingMenuItem;
