import { ISubtitle } from "../types";

// https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
export const stringInterpolate = (str: string, data: Record<string, any>) => {
  Object.entries(data).forEach(([key, value]) => {
    str = str?.replace(`{{${key}}}`, value);
  });
  return str;
};

export const formatVideoTime = (time: number) => {
  try {
    const date = new Date(0);
    date.setSeconds(time);
    const timeString = date.toISOString().slice(11, 19);
    const result = timeString.startsWith("00:0")
      ? timeString.slice(4)
      : timeString.startsWith("00")
      ? timeString.slice(3)
      : timeString;
    return result;
  } catch (error) {
    return "0:00";
  }
};

export function download(url: string, name: string) {
  const elink = document.createElement("a");
  elink.style.display = "none";
  elink.href = url;
  elink.download = name;
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
}

export const sortDefaultLanguage = (
  subtitles: ISubtitle[],
  defaultLanguage: string = "Portuguese",
  defaultLang: string = "pt"
) => {
  return subtitles
    .reduce((acc, sub) => {
      if (sub.language.includes("English") || sub.lang === "en") {
        return [sub, ...acc];
      }
      return [...acc, sub];
    }, [] as ISubtitle[])
    .reduce((acc, sub) => {
      if (
        sub.language.includes(defaultLanguage) ||
        sub.lang === defaultLang ||
        sub.lang.includes(defaultLang)
      ) {
        return [sub, ...acc];
      }
      return [...acc, sub];
    }, [] as ISubtitle[]);
};
