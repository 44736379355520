import axios from "axios";
import { PATH_API, USER_AGENT } from "../../constants";
import { ISource, ISubtitle } from "player";
import CryptoJS from "crypto-js";
import { isJson, substringAfter, substringBefore } from "backend/movie-providers/flixhq";
import { IMediaSource } from "backend/movie-providers/types";

const getEmbedKey = async (): Promise<string> => {
  let { data: key } = await axios.get(
    "https://raw.githubusercontent.com/enimax-anime/key/e4/key.txt"
  );
  if (!key) {
    const githubKey = await axios.get("https://github.com/enimax-anime/key/blob/e4/key.txt");
    key = githubKey.data;
    key = substringBefore(
      substringAfter(key, '"blob-code blob-code-inner js-file-line">'),
      "</td>"
    );
  }
  return key;
};

const extractVidcloud = async (
  videoUrl: string,
  isAlternative: boolean = false
): Promise<IMediaSource> => {
  const host = "https://dokicloud.one";
  const host2 = "https://rabbitstream.net";
  const result: { sources: ISource[]; subtitles: ISubtitle[] } = {
    sources: [],
    subtitles: [],
  };
  try {
    const id = videoUrl.split("/").pop()?.split("?")[0];
    const options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Referer: videoUrl,
        "User-Agent": USER_AGENT,
      },
    };
    let sources: { file: string; type: string }[] = [];
    const [{ data: dataSources }, key] = await Promise.all([
      axios.get(
        PATH_API.BYPASS_CORS_WORKERS(
          `${isAlternative ? host2 : host}/ajax/embed-4/getSources?id=${id}`
        ),
        options
      ),
      getEmbedKey(),
    ]);
    if (!isJson(dataSources.sources)) {
      const decryptedVal = CryptoJS.AES.decrypt(dataSources.sources, key).toString(
        CryptoJS.enc.Utf8
      );
      sources = isJson(decryptedVal) ? JSON.parse(decryptedVal) : dataSources.sources;
    }
    const requests = sources.map((source) =>
      axios.get(PATH_API.BYPASS_CORS_WORKERS(source.file), options)
    );
    const responses = await Promise.all(requests);
    const urlsAndSources = responses.reduce((acc, { data }) => {
      const lines = data.split("\n");
      const urls = lines.filter((line: any) => line.includes(".m3u8"));
      const sources = lines.filter((line: any) => line.includes("RESOLUTION="));
      const tdArray = sources.map((s: any, i: any) => {
        const f1 = s.split("x")[1];
        const f2 = urls[i];
        return [f1, f2];
      });
      return acc.concat(tdArray);
    }, []);
    result.sources = urlsAndSources.map(([f1, f2]: any) => ({ url: f2, quality: f1 }));
    result.sources.push({
      url: sources[0].file,
      quality: "Auto",
    });
    result.subtitles = dataSources.tracks
      .filter((s: any) => s.kind !== "thumbnails")
      .map((s: any) => ({
        url: s.file,
        server: "flixhq",
        lang: s.label ? s.label : "Default (maybe)",
        language: s.label ? s.label : "Default (maybe)",
      }));
    return result;
  } catch (err) {
    console.log("err: ", err);
    throw err;
  }
};

export default extractVidcloud;
