interface Document {
  [key: string]: any;
}

interface HTMLElement {
  [key: string]: any;
}

export const checkOnFullScreen = () => {
  const doc: Document = document;
  const fullscreenElementProps = [
    "fullscreenElement",
    "webkitFullscreenElement",
    "webkitCurrentFullScreenElement",
    "mozFullScreenElement",
    "msFullscreenElement"
  ];
  return fullscreenElementProps.find((prop) => doc[prop]);
};

export const getRequestFullScreen = (element: HTMLElement) => {
  const fullscreenRequestProps = [
    "requestFullscreen",
    "webkitRequestFullscreen",
    "webkitRequestFullScreen",
    "webkitEnterFullscreen",
    "mozRequestFullScreen",
    "msRequestFullscreen"
  ];
  const exitMethod = fullscreenRequestProps.find((prop) => !!element[prop]);
  if (!exitMethod) return;
  return element[exitMethod];
};

export const getRequestExistFullScreen = () => {
  const doc: Document = document;
  const fullscreenExitProps = [
    "exitFullscreen",
    "webkitExitFullscreen",
    "webkitCancelFullScreen",
    "mozCancelFullScreen",
    "msExitFullscreen"
  ];
  const exitMethod = fullscreenExitProps.find((prop) => !!doc[prop]);
  if (!exitMethod) return;
  return doc[exitMethod];
};
