import IconCheck from "../../components/Icons/IconCheck";
import IconSettings from "../../components/Icons/IconSettings";
import IconToggleSubtitle from "../../components/Icons/IconToggleSubtitle";
import { emptySubtitle } from "../../constants";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import OpenSubtitlesLangs from "../extra-subtitles/opensubtitles/OpenSubtitlesLangs";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SubtitleUploader from "../subtitles/SubtitleUploader";
import SettingMenuChecked from "./SettingMenuChecked";
import SettingMenuItem from "./SettingMenuItem";
import SettingSubtitleCustomMethods from "./SettingSubtitleCustomMethods";

const SettingSubtitles = () => {
  const { subtitles } = useGlobalStateContext();
  const { i18n } = useI18nContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { sections, activeSection, setActiveSection } = useSettingsContext();
  return (
    <>
      <div
        ref={sections[SettingSections.subtitles]}
        className={`embed-settings-section ${
          activeSection === SettingSections.subtitles
            ? "embed-settings-translate-center"
            : "embed-settings-translate-right"
        }`}
      >
        <SettingMenuHeader
          title={i18n?.settingsSubtitles || defaultI18n.settingsSubtitles}
          backSectionName={SettingSections.main}
        />
        <div className="embed-settings-py">
          <SettingMenuItem
            settingSection={SettingSections.opensubtitlesLangs}
            icon={<IconToggleSubtitle className="embed-icon-md" />}
            title={i18n?.settingsSubtitlesAdvanced || defaultI18n?.settingsSubtitlesAdvanced}
          />
          <SettingMenuItem
            settingSection={SettingSections.subtitleCustomMethods}
            icon={<IconSettings className="embed-icon-md" />}
            title={i18n?.settingsCustomSubtitles || defaultI18n?.settingsCustomSubtitles}
          />
          <SubtitleUploader />
          <div
            onClick={() => {
              setMediaState((prevState) => ({
                ...prevState,
                subtitleSelected: emptySubtitle,
              }));
              setActiveSection(SettingSections.main);
            }}
            className="embed-settings-item-2"
          >
            {mediaState.subtitleSelected.url ? (
              <div style={{ width: 24, height: 24, flexShrink: 0 }}></div>
            ) : (
              <IconCheck className="w-6 h-6" />
            )}
            <span>{i18n?.settingsSubtitlesOff || "Off"}</span>
          </div>
          {subtitles?.map((subtitle, index) => (
            <SettingMenuChecked
              key={subtitle.lang + subtitle.url + index}
              handleOnClick={() => {
                setMediaState((prevState) => ({
                  ...prevState,
                  subtitleSelected: subtitle,
                }));
              }}
              settingSection={SettingSections.main}
              isActive={mediaState.subtitleSelected.url === subtitle.url}
              value={subtitle.language}
            />
          ))}
        </div>
      </div>
      {[
        SettingSections.subtitles,
        SettingSections.subtitleFontsize,
        SettingSections.subtitleCustomMethods,
        SettingSections.subtitleBackgroundOpacity,
      ].includes(activeSection) ? (
        <SettingSubtitleCustomMethods />
      ) : null}
      {[
        SettingSections.subtitles,
        SettingSections.subtitlesAvanced,
        SettingSections.opensubtitlesLangs,
        SettingSections.opensubtitlesSubs,
      ].includes(activeSection) ? (
        <OpenSubtitlesLangs />
      ) : null}
    </>
  );
};

export default SettingSubtitles;
