import React, { useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import storage from "../constants/localStorage";

export interface IVideoFewUpdatesContext {
  playbackSpeed: number;
  loadedData: boolean;
  loading: boolean;
  volume: number;
  duration: number;
  isMuted: boolean;
}

interface VideoFewUpdatesContextProviderProps {
  children: React.ReactNode;
}

export interface IVideoFewUpdatesContextFewUpdatesContext {
  videoFewUpdatesContext: IVideoFewUpdatesContext;
  setVideoFewUpdatesContext: React.Dispatch<React.SetStateAction<IVideoFewUpdatesContext>>;
}

const defaultVideoFewUpdatesContext: IVideoFewUpdatesContext = {
  playbackSpeed: Number(storage.getSpeed()),
  loading: false,
  loadedData: false,
  duration: 0,
  volume: isNaN(parseInt(storage.getVolume())) ? 100 : Number(storage.getVolume()),
  isMuted: Boolean(Number(storage.getIsMuted()))
};

const VideoFewUpdatesContext = createContext<IVideoFewUpdatesContextFewUpdatesContext>({
  videoFewUpdatesContext: defaultVideoFewUpdatesContext,
  setVideoFewUpdatesContext: () => {}
});

function VideoFewUpdatesContextProvider(props: VideoFewUpdatesContextProviderProps) {
  const [videoFewUpdatesContext, setVideoFewUpdatesContext] = useState(
    defaultVideoFewUpdatesContext
  );
  const value = useMemo(
    () => ({ videoFewUpdatesContext, setVideoFewUpdatesContext }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      videoFewUpdatesContext.duration,
      videoFewUpdatesContext.isMuted,
      videoFewUpdatesContext.loadedData,
      videoFewUpdatesContext.loading,
      videoFewUpdatesContext.playbackSpeed,
      videoFewUpdatesContext.volume
    ]
  );
  return (
    <VideoFewUpdatesContext.Provider value={value}>
      {props.children}
    </VideoFewUpdatesContext.Provider>
  );
}

function useVideoFewUpdatesContext() {
  const context = useContextSelector(VideoFewUpdatesContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error(
      "useVideoFewUpdatesContext must be used within a VideoFewUpdatesContextProvider"
    );
  }
  return context;
}

export { VideoFewUpdatesContextProvider, useVideoFewUpdatesContext };
