import { ExtractFnReturnType, QueryConfig, queryClient } from "../../libs/react-query";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { OpenSubtitlesType } from "./types";
import { PATH_API } from "../../constants";

export const getOpenSubtitles = async (
  imdb_id: string
): Promise<OpenSubtitlesType.ISubtitleItem[]> => {
  try {
    const { data: dataOpenSubtitles } = await axios.get(
      `${PATH_API.API_OPENSUBTITLES}https://rest.opensubtitles.org/search/imdbid-${imdb_id.replace(
        "tt",
        ""
      )}`
    );
    return dataOpenSubtitles;
  } catch (error) {
    console.log("getOpenSubtitles error: ", error);
    return [];
  }
};

type QueryFnType = typeof getOpenSubtitles;

interface IParamsUseOpenSubtitles {
  imdb_id: string;
}

type UseOpenSubtitlesOptions = {
  params: IParamsUseOpenSubtitles;
  config?: QueryConfig<QueryFnType>;
};

const primaryKey = "OpenSubtitles";

export const useOpenSubtitles = ({ config = {}, params }: UseOpenSubtitlesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [primaryKey, params.imdb_id],
    queryFn: () => getOpenSubtitles(params.imdb_id),
  });
};

export const getQueryDataOpenSubtitles = (params: IParamsUseOpenSubtitles) => {
  return queryClient?.getQueryData(
    [primaryKey, params.imdb_id],
    {}
  ) as OpenSubtitlesType.ISubtitleItem[];
};
