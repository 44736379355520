import { getQueryDataOpenSubtitles } from "../../../../backend/opensubtitles";
import { useGlobalStateContext } from "../../../contexts/GlobalStateContext";
import { SettingSections, useSettingsContext } from "../../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../../settings/SettingMenuHeader";
import SubtitleAdvancedSubs from "../../subtitles/SubtitleAdvancedSubs";

const OpenSubtitlesSubs = () => {
  const { meta } = useGlobalStateContext();
  const { mediaState } = useSubtitleStateContext();
  const { sections, activeSection } = useSettingsContext();
  const subtitlesOpensubtitles = getQueryDataOpenSubtitles({ imdb_id: meta?.imdb_id as string });
  const subtitles =
    subtitlesOpensubtitles?.find(
      (subs) => subs.languageName === mediaState.subtitleLangNameSelected
    )?.subtitles || [];
  return (
    <div
      ref={sections[SettingSections.opensubtitlesSubs]}
      className={`embed-settings-section ${
        activeSection === SettingSections.opensubtitlesSubs
          ? "embed-settings-translate-center"
          : "embed-settings-translate-right"
      }`}
    >
      <SettingMenuHeader
        title={mediaState.subtitleLangNameSelected}
        backSectionName={SettingSections.opensubtitlesLangs}
      />
      <SubtitleAdvancedSubs
        subtitles={subtitles}
        nameSectionLangs={SettingSections.opensubtitlesSubs}
      />
    </div>
  );
};

export default OpenSubtitlesSubs;
