import { ChangeEvent } from "react";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import { defaultI18n } from "../../constants/i18n";

const SettingSubtitleLatency = () => {
  const { i18n } = useI18nContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const handleChangeLatency = (e: ChangeEvent<HTMLInputElement>) => {
    setMediaState((prevState) => ({ ...prevState, latency: e.target.value }));
  };
  return (
    <div className="flex items-center min-h-[45px] px-[15px] justify-between">
      <div className="embed-settings-item-left">
        {i18n.settingsLatency || defaultI18n.settingsLatency}{" "}
        {mediaState.latency ? `(${mediaState.latency}s)` : null}
      </div>
      <div className="flex items-center gap-x-[10px]">
        <input
          type="number"
          className="w-[150px] px-2 py-1 rounded bg-[#333]"
          name="latency"
          required
          min="-7200"
          max="7200"
          value={mediaState.latency}
          placeholder="0s"
          onChange={handleChangeLatency}
        />
      </div>
    </div>
  );
};

export default SettingSubtitleLatency;
