import Hls, { HlsConfig } from "hls.js";
import React, { RefObject, VideoHTMLAttributes, memo, useEffect } from "react";
import {
  IVideoLotsOfUpdates,
  useVideoLotsOfUpdatesContext,
} from "../../contexts/VideoLotsOfUpdatesContext";
import { ISource } from "../../types";

export interface HlsPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  hlsConfig?: HlsConfig;
  playerRef: RefObject<HTMLVideoElement>;
  currentSource: ISource;
}

interface VideoHlsProps extends HlsPlayerProps {
  setVideoLotsOfUpdatesContext: React.Dispatch<React.SetStateAction<IVideoLotsOfUpdates>>;
}

const VideoHlsMemo = (props: VideoHlsProps) => {
  const {
    setVideoLotsOfUpdatesContext,
    playerRef = React.createRef<HTMLVideoElement>(),
    src,
    autoPlay,
    currentSource,
    ...rest
  } = props;
  useEffect(() => {
    let hls: Hls;
    function _initPlayer() {
      if (hls != null) hls.destroy();
      const newHls = new Hls({
        // xhrSetup: (xhr, url) => {
        //   const reqUrl = currentSource.proxy ? PATH_RESOURCE.CORS_PROXY_IO(url) : url;
        //   xhr.open("GET", reqUrl, true);
        // },
        enableWorker: false,
      });
      if (playerRef.current != null) newHls.attachMedia(playerRef.current);
      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);
        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerRef?.current?.play().catch(() => {
              console.log("Unable to autoplay prior to user interaction with the dom.");
            });
            setVideoLotsOfUpdatesContext((prevState) => ({ ...prevState, paused: true }));
          }
        });
      });
      newHls.on(Hls.Events.ERROR, function (_event, data) {
        console.error("Hls error: ", _event, data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              break;
          }
        }
      });
      hls = newHls;
    }
    // Check for Media Source support
    if (Hls.isSupported()) _initPlayer();
    return () => {
      if (hls != null) hls.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, playerRef, src]);
  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported()) return <video ref={playerRef} {...rest} />;
  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return <video ref={playerRef} src={src} autoPlay={autoPlay} {...rest} />;
};

VideoHlsMemo.displayName = "VideoHlsMemo";

function VideoHls({ playerRef = React.createRef<HTMLVideoElement>(), ...props }: HlsPlayerProps) {
  const { setVideoLotsOfUpdatesContext } = useVideoLotsOfUpdatesContext();
  return (
    <VideoHlsMemo
      playerRef={playerRef}
      setVideoLotsOfUpdatesContext={setVideoLotsOfUpdatesContext}
      {...props}
    />
  );
}

export default VideoHls;
