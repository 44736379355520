import React from "react";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import { useVideoLotsOfUpdatesContext } from "../../contexts/VideoLotsOfUpdatesContext";
import CircularProgress from "../../components/Icons/CircularProgress";
import VideoPlayEffect from "./VideoPlayEffect";
import useVideoProps from "../../hooks/useVideoProps";

const DesktopCenterControls = () => {
  const { handleScreenClicked } = useVideoProps();
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoLotsOfUpdatesContext } = useVideoLotsOfUpdatesContext();
  return (
    <>
      {(!videoFewUpdatesContext.loadedData ||
        (videoFewUpdatesContext.loading && !videoLotsOfUpdatesContext.paused)) && (
        <div className="embed-center">
          <CircularProgress />
        </div>
      )}
      {!videoLotsOfUpdatesContext.paused && videoFewUpdatesContext.loadedData && (
        <div className="embed-center" onClickCapture={handleScreenClicked}>
          <VideoPlayEffect />
        </div>
      )}
    </>
  );
};

export default DesktopCenterControls;
