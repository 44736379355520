import axios from "axios";

export const tmdbEndpoint = "https://api.themoviedb.org/3";
export const apiKey = "95f2419536f533cdaa1dadf83c606027";

const axiosTmdb = axios.create({
  baseURL: tmdbEndpoint,
  params: {
    with_original_language: "pt",
    // language: "pt-BR",
  },
});

axiosTmdb.interceptors.request.use(
  async (config) => {
    config.params = {
      ...config.params,
      api_key: apiKey,
      // language: "pt-BR",
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosTmdb.interceptors.response.use(
  async (response) => {
    if (response && response.data) return response.data;
    return response;
  },
  async (error) => {
    const { response } = error;
    const errorResult = { ...response?.data, status: response?.status };
    return Promise.reject(errorResult);
  }
);

export default axiosTmdb;
