import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

interface SettingsProviderProps {
  children: React.ReactNode;
}

export enum SettingSections {
  main = "main",
  speed = "speed",
  subtitles = "subtitles",
  subtitleFontsize = "subtitleFontsize",
  subtitleCustomMethods = "subtitleCustomMethods",
  subtitleBackgroundOpacity = "settingBackgroundCaption",
  qualities = "qualities",
  subtitlesAvanced = "subtitlesAvanced",
  opensubtitlesLangs = "opensubtitlesLangs",
  opensubtitlesSubs = "opensubtitlesSubs",
}

export interface ISettingsContext {
  sections: { [key in SettingSections]: RefObject<HTMLDivElement> | null };
  activeSection: SettingSections;
  setActiveSection: React.Dispatch<React.SetStateAction<SettingSections>>;
  height: number;
  updateHeightSettingsDialog: (height?: number) => void;
}

const SettingsContext = createContext<ISettingsContext>({
  sections: {
    [SettingSections.main]: null,
    [SettingSections.speed]: null,
    [SettingSections.subtitles]: null,
    [SettingSections.subtitleFontsize]: null,
    [SettingSections.subtitleCustomMethods]: null,
    [SettingSections.subtitleBackgroundOpacity]: null,
    [SettingSections.qualities]: null,
    [SettingSections.opensubtitlesLangs]: null,
    [SettingSections.opensubtitlesSubs]: null,
    [SettingSections.subtitlesAvanced]: null,
  },
  activeSection: SettingSections.main,
  setActiveSection: () => {},
  height: 0,
  updateHeightSettingsDialog: () => {},
});

function SettingsProvider(props: SettingsProviderProps) {
  const [height, setHeight] = useState(200);
  const [activeSection, setActiveSection] = useState(SettingSections.main);
  const mainRef = useRef<HTMLDivElement>(null);
  const speedRef = useRef<HTMLDivElement>(null);
  const subtitlesRef = useRef<HTMLDivElement>(null);
  const customSubtitlesRef = useRef<HTMLDivElement>(null);
  const qualitisRef = useRef<HTMLDivElement>(null);
  const subtitleFontSizeRef = useRef<HTMLDivElement>(null);
  const opensubtitleLangsRef = useRef<HTMLDivElement>(null);
  const opensubittleSubsRef = useRef<HTMLDivElement>(null);
  const subtitleBackgroundOpacityRef = useRef<HTMLDivElement>(null);
  const subtitleAvancedRef = useRef<HTMLDivElement>(null);
  const sections = useMemo<{ [key in SettingSections]: RefObject<HTMLDivElement> }>(
    () => ({
      [SettingSections.main]: mainRef,
      [SettingSections.speed]: speedRef,
      [SettingSections.subtitles]: subtitlesRef,
      [SettingSections.subtitleCustomMethods]: customSubtitlesRef,
      [SettingSections.qualities]: qualitisRef,
      [SettingSections.subtitleFontsize]: subtitleFontSizeRef,
      [SettingSections.subtitleBackgroundOpacity]: subtitleBackgroundOpacityRef,
      [SettingSections.opensubtitlesLangs]: opensubtitleLangsRef,
      [SettingSections.opensubtitlesSubs]: opensubittleSubsRef,
      [SettingSections.subtitlesAvanced]: subtitleAvancedRef,
    }),
    []
  );
  const updateHeightSettingsDialog = useCallback(
    (height?: number) => {
      if (sections[activeSection]?.current) {
        if (height) {
          setHeight(height);
        } else {
          const elementHeight = Number(sections[activeSection].current?.offsetHeight);
          setHeight(elementHeight > 400 ? 400 : elementHeight);
        }
        sections[activeSection].current?.scrollTo &&
          sections[activeSection].current?.scrollTo(0, 0);
      }
    },
    [activeSection, sections]
  );
  useEffect(() => {
    updateHeightSettingsDialog();
  }, [updateHeightSettingsDialog]);
  const contextValue = useMemo(
    () => ({ sections, activeSection, setActiveSection, height, updateHeightSettingsDialog }),
    [sections, activeSection, setActiveSection, updateHeightSettingsDialog, height]
  );
  return <SettingsContext.Provider value={contextValue}>{props.children}</SettingsContext.Provider>;
}

function useSettingsContext() {
  const context = useContextSelector(SettingsContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error("useSettingsContext must be used within a SettingsProvider");
  }
  return context;
}

export { SettingsProvider, useSettingsContext };
