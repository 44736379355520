import { allServers, useMediaSources } from "backend/movie-providers/main-providers";
import { IParamsMediaSource } from "backend/movie-providers/types";
import { imageTMDB } from "backend/tmdb/image";
import { TMDBType } from "backend/tmdb/types";
import { useEpisodeDetailsTmdb } from "backend/tmdb/useEpisodeDetailsSeries";
import { useSeriesDetailsTmdb } from "backend/tmdb/useSeriesDetailsTmdb";
import EmbedTmdbPlayer from "components/EmbedTmdbPlayer";
import LoadingMovieScreen from "components/LoadingMovieScreen";
import PopoverServersPicker from "components/PopoverServersPicker";
import SeriesSourcesPicker from "components/SeriesSourcesPicker";
import EmbedPlayer from "player";
import { WRAPPER_PLAYER_ID } from "player/constants";
import useSaveWatched from "player/hooks/useSavedWatched";
import { sortDefaultLanguage } from "player/utils/helper";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useVideoServersStore from "store";
import { MediaType } from "types";
import { getTitleFromSeriesDetails } from "utils/helper";

const WatchSeriesPage = () => {
  const [searchParams] = useSearchParams();
  const tmdb_id = searchParams.get("tmdb_id");
  const episodeId = Number(searchParams.get("episode") || 1);
  const seasonId = Number(searchParams.get("season") || 1);
  const { data: details, isLoading: isLoadingTmdb } = useSeriesDetailsTmdb({
    params: {
      tmdb_id: tmdb_id || "",
    },
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * (60 * 1000),
      enabled: Boolean(tmdb_id),
    },
  });
  const { data: episodeDetailsTmdb } = useEpisodeDetailsTmdb({
    params: {
      tmdb_id: details?.id as number,
      episodeId,
      seasonId,
    },
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * (60 * 1000),
      enabled: Boolean(details?.id),
    },
  });
  const paramsMediaSources: IParamsMediaSource = {
    title: getTitleFromSeriesDetails(details as TMDBType.ISeriesDetails),
    extraData: {
      tmdb_id: details?.id,
      imdb_id: episodeDetailsTmdb?.external_ids.imdb_id,
      mediaType: MediaType.TV,
      titlePortuguese: details?.title_portuguese,
      year: new Date(details?.first_air_date as any).getFullYear(),
      episodeId,
      seasonId,
    },
  };
  const { startTime, handleSaveProgressWatched } = useSaveWatched({
    details: details as TMDBType.ISeriesDetails,
    title: getTitleFromSeriesDetails(details as TMDBType.ISeriesDetails),
    mediaType: MediaType.MOVIE,
  });
  const { activeServer, setActiveServer, setServers } = useVideoServersStore((state) => state);
  const { isLoading: isLoadingSources, data: dataMediaSources } = useMediaSources({
    params: paramsMediaSources,
    config: {
      enabled: Boolean(details?.id),
      onSuccess(data) {
        setServers(data.servers);
        setActiveServer(data.activeServer);
      },
    },
  });
  useEffect(() => {
    setServers(allServers);
    setActiveServer(allServers[0].name);
  }, [tmdb_id, setActiveServer, setServers]);
  if (isLoadingTmdb) return <LoadingMovieScreen />;
  if (activeServer === "Embed") {
    return (
      <EmbedTmdbPlayer
        urlEmbed={`https://embed.embedplayer.site/tv/${details?.id}/${seasonId}/${episodeId}/dub`}
        handleGoBack={() => {
          setServers(allServers);
          setActiveServer(allServers[0].name);
        }}
      />
    );
  }
  return (
    <div className="fixed w-full h-full" id={WRAPPER_PLAYER_ID}>
      <EmbedPlayer
        poster={imageTMDB.imageOriginal(details?.backdrop_path as string)}
        sources={dataMediaSources?.mediaSources.sources || []}
        subtitles={sortDefaultLanguage(dataMediaSources?.mediaSources.subtitles || [])}
        meta={{
          imdb_id: paramsMediaSources.extraData.imdb_id,
          title: paramsMediaSources.title,
          year: paramsMediaSources.extraData.year,
        }}
        startTime={startTime}
        onTimeUpdate={handleSaveProgressWatched}
        heading={`S${seasonId} E${episodeId}: ${getTitleFromSeriesDetails(
          details as TMDBType.ISeriesDetails
        )}`}
        rightControlsComponent={
          <>
            <SeriesSourcesPicker details={details as TMDBType.ISeriesDetails} />
            <PopoverServersPicker
              isLoading={isLoadingSources}
              paramsMediaSources={paramsMediaSources}
            />
          </>
        }
      />
    </div>
  );
};

export default WatchSeriesPage;
