import chardet from "chardet";
import JSZip from "jszip";

export const getSubtitleFromLinkZip = async (url: string) => {
  const response = await fetch(url);
  const downloadBlob = await response.blob();
  const zip = await JSZip.loadAsync(downloadBlob);
  const unzippedFiles = Object.keys(zip.files);
  const foundFileSubtitle = unzippedFiles.find((f) => f.endsWith(".srt") || f.endsWith(".vtt"));
  if (!foundFileSubtitle) return "";
  const fileSubtitle = zip.file(foundFileSubtitle);
  const fileSubtitleUint8array = await fileSubtitle?.async("uint8array");
  if (!fileSubtitleUint8array) return "";
  const type = chardet.detect(fileSubtitleUint8array);
  const decoder = new TextDecoder(type || "ISO-8859-1");
  const decoded = decoder.decode(fileSubtitleUint8array?.buffer);
  return decoded;
};
