import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import IconQuality from "../../components/Icons/IconQuality";
import IconSelectSubtitle from "../../components/Icons/IconSelectSubtitle";
import IconSpeed from "../../components/Icons/IconSpeed";
import SettingMenuItem from "./SettingMenuItem";

const SettingMain = () => {
  const { i18n } = useI18nContext();
  const { subtitles } = useGlobalStateContext();
  const { sections, activeSection } = useSettingsContext();
  return (
    <div
      ref={sections[SettingSections.main]}
      className={`embed-settings-section ${
        activeSection === SettingSections.main
          ? "embed-settings-translate-center"
          : "embed-settings-translate-left"
      }`}
    >
      <div className="embed-settings-py">
        <SettingMenuItem
          settingSection={SettingSections.speed}
          icon={<IconSpeed className="embed-icon-md" />}
          title={i18n?.settingsPlaybackSpeed || defaultI18n.settingsPlaybackSpeed}
        />
        {subtitles && (
          <SettingMenuItem
            settingSection={SettingSections.subtitles}
            icon={<IconSelectSubtitle className="embed-icon-md" />}
            title={i18n?.settingsSubtitles || defaultI18n.settingsSubtitles}
          />
        )}
        <SettingMenuItem
          settingSection={SettingSections.qualities}
          icon={<IconQuality className="embed-icon-md" />}
          title={i18n?.settingsQuality || defaultI18n.settingsQuality}
        />
      </div>
    </div>
  );
};

export default SettingMain;
