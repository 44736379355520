import { defaultI18n } from "../../../constants/i18n";
import { useGlobalStateContext } from "../../../contexts/GlobalStateContext";
import { useI18nContext } from "../../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../../settings/SettingMenuHeader";
import SettingMenuItem from "../../settings/SettingMenuItem";
import IconCheck from "../../../components/Icons/IconCheck";
import OpenSubtitlesSubs from "./OpenSubtitlesSubs";
import { useEffect } from "react";
import { baseUrlDownloadOpensubtitles, useOpenSubtitles } from "../../../../backend/opensubtitles";
import { flags } from "./flags";

const OpenSubtitlesLangs = () => {
  const { i18n } = useI18nContext();
  const { meta } = useGlobalStateContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { sections, activeSection, updateHeightSettingsDialog } = useSettingsContext();
  const { isLoading, data: dataOpenSubtitles = [] } = useOpenSubtitles({
    params: {
      imdb_id: meta?.imdb_id as string,
    },
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * (60 * 1000),
      enabled: Boolean(activeSection === SettingSections.opensubtitlesLangs && meta?.imdb_id),
      // onSuccess(data) {
      //   updateHeightSettingsDialog(70 + data.length * 45);
      // }
    },
  });
  useEffect(() => {
    updateHeightSettingsDialog();
  }, [isLoading, updateHeightSettingsDialog]);
  return (
    <>
      <div
        ref={sections[SettingSections.opensubtitlesLangs]}
        className={`embed-settings-section ${
          activeSection === SettingSections.opensubtitlesLangs
            ? "embed-settings-translate-center"
            : "embed-settings-translate-left"
        }`}
      >
        <SettingMenuHeader
          title={i18n?.settingsSubtitlesAdvanced || defaultI18n.settingsSubtitlesAdvanced}
          backSectionName={SettingSections.subtitles}
        />
        <div className="embed-settings-py">
          {isLoading ? (
            <div className="px-4 py-2">Finding subtitles...</div>
          ) : (
            dataOpenSubtitles?.map((subtitle) => {
              const isActive =
                mediaState.subtitleLangNameSelected === subtitle.languageName &&
                mediaState.subtitleSelected.url.startsWith(baseUrlDownloadOpensubtitles);
              return (
                <SettingMenuItem
                  key={subtitle.languageName}
                  settingSection={SettingSections.opensubtitlesSubs}
                  icon={
                    isActive ? (
                      <IconCheck className="w-6 h-6 text-[#0086d4]" fill="#0086d4" />
                    ) : (
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          flexShrink: 0,
                        }}
                      >
                        <img
                          src={flags[subtitle.languageName as keyof typeof flags]}
                          alt={subtitle.languageName}
                        />
                      </div>
                    )
                  }
                  style={{ color: isActive ? "#0086d4" : "#fff" }}
                  value={`(${subtitle.subtitles.length})`}
                  title={subtitle.languageName}
                  handleOnClick={() => {
                    setMediaState((prevState) => ({
                      ...prevState,
                      subtitleSelected: prevState.subtitleSelected,
                      subtitleLangNameSelected: subtitle.languageName,
                    }));
                  }}
                />
              );
            })
          )}
        </div>
      </div>
      {[SettingSections.opensubtitlesLangs, SettingSections.opensubtitlesSubs].includes(
        activeSection
      ) ? (
        <OpenSubtitlesSubs />
      ) : null}
    </>
  );
};

export default OpenSubtitlesLangs;
