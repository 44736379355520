import classNames from "utils/classNames";

interface IconEpHoverProps extends React.SVGProps<SVGSVGElement> {}

const IconEpHover = ({ className, ...props }: IconEpHoverProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("text-blue-300", className)}
      {...props}
    >
      <path
        opacity="0.4"
        d="M12 0.75C5.78677 0.75 0.75 5.78677 0.75 12C0.75 18.2132 5.78677 23.25 12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.78677 18.2132 0.75 12 0.75ZM17.4617 12.96L10.7117 17.085C10.5319 17.1949 10.3286 17.2502 10.125 17.2502C9.48314 17.2502 9 16.7253 9 16.1252V7.87519C9 7.28034 9.47798 6.75056 10.1253 6.75056C10.3289 6.75056 10.5321 6.80569 10.7117 6.91533L17.4617 11.0403C17.796 11.2447 18 11.6084 18 12.0002C18 12.392 17.796 12.7557 17.4617 12.96Z"
        fill="currentColor"
      />
      <path
        d="M10.125 17.2506C9.48319 17.2506 9 16.7257 9 16.1256V7.87555C9 7.28071 9.47803 6.75098 10.1253 6.75098C10.3289 6.75098 10.532 6.80605 10.7117 6.91574L17.4617 11.0407C17.796 11.2451 18 11.6087 18 12.0006C18 12.3924 17.796 12.756 17.4617 12.9604L10.7117 17.0854C10.5319 17.1953 10.3286 17.2506 10.125 17.2506Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconEpHover;
