const storagePlayerPrefix = "embedPlayer";

export const defaultSubtitleSettings = { fontSize: 1, backgroundOpacity: 1, color: "#ffffff" };

const storage = {
  getSubtitleSettings: () => {
    return JSON.parse(
      localStorage.getItem(`${storagePlayerPrefix}-settings-subtitle`) ||
        JSON.stringify(defaultSubtitleSettings)
    );
  },
  getVolume: () => {
    return localStorage.getItem(`${storagePlayerPrefix}-volume`) || "";
  },
  getIsMuted: () => {
    return localStorage.getItem(`${storagePlayerPrefix}-muted`) || "";
  },
  getSpeed: () => {
    return localStorage.getItem(`${storagePlayerPrefix}-speed`) || 1;
  },
};

export default storage;
