interface IconArrowRightProps extends React.SVGProps<SVGSVGElement> {}

const IconArrowRight = ({ ...props }: IconArrowRightProps) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 24 24"
      className="w-8 h-8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
    </svg>
  );
};

export default IconArrowRight;
