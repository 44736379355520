import { get, sortSourceSuperstream, sortSubtitlesSuperstream } from "./utils";

export const getSourcesSeries = async (moviedId: number, episodeId: number, seasonId: number) => {
  const apiQuery = {
    uid: "",
    module: "TV_downloadurl_v3",
    tid: moviedId,
    episode: episodeId,
    season: seasonId,
    oss: "1",
    group: ""
  };
  const subtitleApiQuery = {
    fid: "",
    uid: "",
    module: "TV_srt_list_v2",
    episode: episodeId,
    tid: moviedId,
    season: seasonId
  };
  try {
    const [mediaRes, subtitleRes] = await Promise.all([
      get(apiQuery).then((r) => r.json()),
      get(subtitleApiQuery).then((r) => r.json())
    ]);
    return {
      sources: sortSourceSuperstream(mediaRes.data.list),
      subtitles: sortSubtitlesSuperstream(subtitleRes.data.list)
    };
  } catch (error) {
    throw new Error("Unable to fetch movie stream");
  }
};
