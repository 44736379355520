import { imageTMDB } from "backend/tmdb/image";
import { TMDBType } from "backend/tmdb/types";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MediaType } from "types";
import { randomString } from "utils/helper";

export interface IHistoryItem {
  key: string;
  id: number;
  name: string;
  url: string;
  description: string;
  background: string;
  poster: string;
  mediaType: MediaType;
  currentEpisode: number | string;
  progress: number;
  totalDuration: number;
  currentTime: number;
}

const MAX_HISTORY_LENGTH = 30;
const localStorageHistoryKey = "local-history";

const getHistoryLocalStorage = (): IHistoryItem[] => {
  return JSON.parse(localStorage.getItem(localStorageHistoryKey) || "[]");
};

interface useSaveWatchedType {
  title: string;
  details: TMDBType.IMovieDetails | TMDBType.ISeriesDetails;
  mediaType: MediaType;
}

const useSaveWatched = ({ title, details, mediaType }: useSaveWatchedType) => {
  const [searchParams] = useSearchParams();
  const tmdb_id = searchParams.get("tmdb_id");
  const episodeId = Number(searchParams.get("episode") || 1);
  const [startTime, setStartTime] = useState(0);
  const handleSaveProgressWatched = (e: SyntheticEvent<HTMLVideoElement>) => {
    const node = e.target as HTMLVideoElement;
    if (isNaN(node.duration)) return;
    const historyStorage = getHistoryLocalStorage();
    if (!historyStorage) return;
    const foundWatchedMovieIndex = historyStorage.findIndex((history) => {
      return history.id === details.id && history.url === window.location.pathname;
    });
    const percentProgress = (node.currentTime / node.duration) * 100;
    if (foundWatchedMovieIndex === -1) return;
    historyStorage[foundWatchedMovieIndex].currentTime = node.currentTime;
    historyStorage[foundWatchedMovieIndex].totalDuration = Math.floor(node.duration);
    historyStorage[foundWatchedMovieIndex].progress = percentProgress;
    localStorage.setItem(localStorageHistoryKey, JSON.stringify(historyStorage));
  };
  useEffect(() => {
    if (!details) return;
    let historyLS = getHistoryLocalStorage();
    const foundIndexHistory = historyLS.findIndex(
      (movie) => movie.id === details.id && movie.url === window.location.pathname
    );
    const isFoundHistory = foundIndexHistory !== -1;
    const history: IHistoryItem = {
      key: randomString(),
      id: details.id,
      url: window.location.pathname,
      name: title,
      mediaType,
      poster: imageTMDB.image185(details.poster_path),
      background: imageTMDB.imageOriginal(details.backdrop_path),
      description: details.overview,
      currentEpisode: episodeId,
      currentTime: isFoundHistory ? historyLS[foundIndexHistory].currentTime : 0,
      totalDuration: 0,
      progress: isFoundHistory ? historyLS[foundIndexHistory].progress : 0,
    };
    if (isFoundHistory) {
      setStartTime(historyLS[foundIndexHistory].currentTime);
      historyLS.splice(foundIndexHistory, 1);
    } else {
      setStartTime(0);
    }
    if (historyLS.length >= MAX_HISTORY_LENGTH) {
      historyLS = historyLS.slice(0, MAX_HISTORY_LENGTH);
    }
    localStorage.setItem(localStorageHistoryKey, JSON.stringify([history, ...historyLS]));
  }, [details, tmdb_id, episodeId, title, mediaType]);
  return { handleSaveProgressWatched, startTime };
};

export default useSaveWatched;
