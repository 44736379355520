import { baseUrlDownloadOpensubtitles } from "../../../../backend/opensubtitles";
import { getSubtitleFromLinkZip } from "./getSubtitleFromLinkZip";

export const getSubtitleText = async (url: string): Promise<string> => {
  try {
    if (url.startsWith(baseUrlDownloadOpensubtitles)) {
      const subText = await getSubtitleFromLinkZip(url);
      return subText;
    }
    if (url.includes("vtt") || url.includes("srt")) {
      const response = await fetch(url);
      const text = await response.text();
      return text;
    }
    return "";
  } catch (error) {
    console.log("error: ", error);
    return "";
  }
};
