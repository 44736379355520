const IconCheck = ({ ...others }) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      {...others}
    >
      <path
        d="M48.9536 8.08584C50.3488 9.53131 50.3488 11.8672 48.9536 13.3127L20.3806 42.9159C18.9854 44.3614 16.7308 44.3614 15.3357 42.9159L1.04604 28.1143C-0.34868 26.6688 -0.34868 24.3329 1.04604 22.8875C2.44098 21.442 4.70227 21.442 6.09744 22.8875L17.7577 35.0641L43.9087 8.08584C45.3039 6.63805 47.5585 6.63805 48.9536 8.08584Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCheck;
