import * as Popover from "@radix-ui/react-popover";
import { useQuery } from "@tanstack/react-query";
import axiosTmdb from "backend/tmdb/axiosTmdb";
import { imageTMDB } from "backend/tmdb/image";
import { ISeason } from "backend/tmdb/types";
import { useSearchParams } from "react-router-dom";
import EpisodeInfo from "./EpisodeInfo";
import { SectionType } from "./SeriesSourcesPicker";

interface SeriesSourceEpisodesPickerProps {
  selectedSeasonId: number;
  fallbackSrc: string;
  handleChangeSection: (sectionName: SectionType) => void;
}

const SeriesSourceEpisodesPicker = (props: SeriesSourceEpisodesPickerProps) => {
  const { selectedSeasonId, fallbackSrc, handleChangeSection } = props;
  const [searchParams] = useSearchParams();
  const tmdb_id = searchParams.get("tmdb_id");
  const episodeId = Number(searchParams.get("episode") || 1);
  const seasonId = Number(searchParams.get("season") || 1);
  const { data: dataActiveSeason } = useQuery({
    queryKey: ["seasons", tmdb_id, selectedSeasonId],
    queryFn: async () => {
      const seasonEpisode: ISeason = await axiosTmdb(`/tv/${tmdb_id}/season/${selectedSeasonId}`, {
        params: { language: "pt-BR" },
      });
      return seasonEpisode;
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * (60 * 1000),
    keepPreviousData: true,
  });
  return (
    <div className="px-3 py-4 md:px-6">
      <div className="flex justify-between pb-5 gap-x-3">
        <div className="flex-1">
          <p className="text-xl font-medium line-clamp-1">{dataActiveSeason?.name}</p>
          <span className="font-light">Escolher episódio</span>
        </div>
        <span
          onClick={() => handleChangeSection("seasons")}
          className="flex-shrink-0 cursor-pointer"
        >
          Outras temporadas
        </span>
      </div>
      <div className="overflow-y-auto scrollbar-styles">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
          {dataActiveSeason?.episodes.map((ep, index) => (
            <Popover.Close key={ep.id} asChild>
              <EpisodeInfo
                title={`${ep.episode_number || index + 1} - ${ep.name}`}
                description={ep.overview}
                fallbackSrc={fallbackSrc}
                imageUrl={imageTMDB.image185(ep.still_path)}
                href={`/watch/tv?tmdb_id=${tmdb_id}&season=${dataActiveSeason.season_number}&episode=${ep.episode_number}`}
                className={
                  Number(episodeId) === ep.episode_number && Number(seasonId) === ep.season_number
                    ? "text-primary"
                    : "text-white"
                }
              >
                E{index + 1}
              </EpisodeInfo>
            </Popover.Close>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeriesSourceEpisodesPicker;
