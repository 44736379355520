import React, { useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

export interface ISeekPreview {
  time: number;
  offset: number;
}

export interface IVideoUpdate {
  currentTime: number;
  seekPreview: null | ISeekPreview;
  hoverEnabled: boolean;
}

interface VideoUpdateProviderProps {
  children: React.ReactNode;
}

export interface IVideoUpdateContext {
  videoUpdate: IVideoUpdate;
  setVideoUpdate: React.Dispatch<React.SetStateAction<IVideoUpdate>>;
}

const defaultVideoUpdate: IVideoUpdate = {
  currentTime: 0,
  seekPreview: null,
  hoverEnabled: true
};

const VideoUpdateContext = createContext<IVideoUpdateContext>({
  videoUpdate: defaultVideoUpdate,
  setVideoUpdate: () => {}
});

function VideoUpdateProvider(props: VideoUpdateProviderProps) {
  const [videoUpdate, setVideoUpdate] = useState(defaultVideoUpdate);
  const value = useMemo(() => {
    return { videoUpdate, setVideoUpdate };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUpdate.currentTime, videoUpdate.seekPreview, videoUpdate.hoverEnabled, setVideoUpdate]);
  return <VideoUpdateContext.Provider value={value}>{props.children}</VideoUpdateContext.Provider>;
}

function useVideoUpdateContext() {
  const context = useContextSelector(VideoUpdateContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error("useVideoUpdateContext must be used within a VideoUpdateProvider");
  }
  return context;
}

export { VideoUpdateProvider, useVideoUpdateContext };
