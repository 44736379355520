import HomePage from "pages/HomePage";
import PageNotFound from "pages/PageNotFound";
import WatchMoviePage from "pages/WatchMoviePage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./styles/global.scss";
import "./styles/player.scss";
import WatchSeriesPage from "pages/WatchSeriesPage";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
    // const parentURL = document.referrer;
    // console.log("Parent URL: " + parentURL);
    // if (parentURL !== "https://donflix.pages.dev/") {
      // window.location.replace("https://donflix.pages.dev/");
    // }
   //}, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/watch/movie" element={<WatchMoviePage />} />
        <Route path="/watch/tv" element={<WatchSeriesPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
